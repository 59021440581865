import { PageCard } from '@/components/cards/PageCard.tsx'
import useResidentialServiceActivityTableColumns, {
  ResidentialServiceActivityTableColumnVisibility,
} from '@/pages/serviceActivity/residential/useResidentialServiceActivityTableColumns.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import { ReactElement, useMemo, useState } from 'react'
import { useResidentialServiceActivityQuery } from '@/api/ServiceActivityQueries.ts'
import SnapshotCard from '@/pages/serviceActivity/residential/snapShots/components/SnapshotCard.tsx'
import {
  AssetStage,
  PageName,
  ResidentialServiceActivity,
  ServiceActivitySnapshot,
} from '@/generated'
import ActiveIcon from '@/pages/serviceActivity/residential/snapShots/icons/ActiveIcon.svg'
import PerformanceVerificationIcon from '@/pages/serviceActivity/residential/snapShots/icons/PerformanceVerificationIcon.svg'
import MetadataVerificationIcon from '@/pages/serviceActivity/residential/snapShots/icons/MetadataVerificationIcon.svg'
import useResidentialServiceActivityTableFilters from '@/pages/serviceActivity/residential/useResidentialServiceActivityTableFilters.tsx'
import PageBody from '@/pages/PageBody.tsx'
import { Row } from '@tanstack/react-table'
import { ServiceActivityPageSize } from '@/features/serviceactivity/ServiceActivityPageSize.ts'
import ServiceActivityTable from '@/pages/serviceActivity/shared/ServiceActivityTable.tsx'
import useResidentialServiceActivityCsvColumns from '@/pages/serviceActivity/residential/useResidentialServiceActivityCsvColumns.ts'
import ServiceActivityCsvContextProvider from '@/pages/serviceActivity/ServiceActivityCsvContextProvider.tsx'
import { datedCsvFileName } from '@/helpers/ExportCsvHelpers.ts'
import ResidentialServiceActivityDrawer from '@/features/serviceactivity/detailsDrawer/ResidentialServiceActivityDrawer.tsx'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import useServiceActivityObjectName from '@/pages/serviceActivity/useServiceActivityObjectName.ts'
import DataRefreshMessage from '@/pages/serviceActivity/shared/DataRefreshMessage.tsx'

type SnapshotConfig = {
  stage: AssetStage
  icon: string
  description: string
  baseTicketLabel: string
  basePercentLabel: string
  showAdditionalFields?: boolean
  toolTip: ReactElement
}

const SNAPSHOTS_CONFIG: SnapshotConfig[] = [
  {
    stage: AssetStage.MetadataVerification,
    icon: MetadataVerificationIcon,
    description: 'Assets in Metadata Verification',
    baseTicketLabel: 'Metadata Verification Tickets',
    basePercentLabel:
      'Percent of assets in Metadata Verification with 1+ tickets',
    toolTip: (
      <>
        The count of <span className="tw-font-bold">open</span> tickets for
        assets in the Metadata Verification asset stage with the ticket type of
        &quot;Metadata Verification&quot;
      </>
    ),
  },
  {
    stage: AssetStage.Pvt,
    icon: PerformanceVerificationIcon,
    description: 'Assets in Performance Verification',
    baseTicketLabel: 'Performance Verification Testing Tickets',
    basePercentLabel: 'Percent of assets in PVT with 1+ tickets',
    showAdditionalFields: true,
    toolTip: (
      <>
        The count of <span className="tw-font-bold">open</span> tickets for
        assets in the PVT asset stage with the ticket type of &quot;Performance
        Verification Test&quot;
      </>
    ),
  },
  {
    stage: AssetStage.Active,
    icon: ActiveIcon,
    description: 'Active Assets',
    baseTicketLabel: 'Tickets for Active assets',
    basePercentLabel: 'Percent of active assets with 1+ tickets',
    showAdditionalFields: true,
    toolTip: (
      <>
        The count of any <span className="tw-font-bold">open</span> tickets for
        assets in the Active asset stage, regardless of ticket type
      </>
    ),
  },
]

export default function ResidentialServiceActivityPage() {
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()
  const objectName = useServiceActivityObjectName()
  const { data, isLoading, isError } = useResidentialServiceActivityQuery()
  const [selectedServiceActivityId, setSelectedServiceActivityId] =
    useState<string>()

  // Table setup
  const rows = useMemo(
    () => data?.serviceActivity ?? [],
    [data?.serviceActivity]
  )
  const columns = useResidentialServiceActivityTableColumns(
    PageName.ServiceActivity
  )
  const filterDefs = useResidentialServiceActivityTableFilters(
    PageName.ServiceActivity
  )
  const exportCsvColumns = useResidentialServiceActivityCsvColumns(
    PageName.ServiceActivity
  )

  const tableModel = useTable(rows, columns, {
    initialSort: [{ id: 'priority', desc: false }],
    columnVisibility: ResidentialServiceActivityTableColumnVisibility,
    pageSize: ServiceActivityPageSize,
  })

  const snapshots: { [x: string]: ServiceActivitySnapshot } = useMemo(
    () =>
      SNAPSHOTS_CONFIG.reduce(
        (acc, config) => ({
          ...acc,
          [config.stage]: data?.snapshots.find(
            item => item.assetStage === config.stage
          ),
        }),
        {}
      ),
    [data?.snapshots]
  )

  const handleClickRow = (row: Row<ResidentialServiceActivity>) => {
    setSelectedServiceActivityId(row.original.id)
  }

  const handleCloseDrawer = () => {
    setSelectedServiceActivityId(undefined)
  }

  const getServiceCloudLabels = (config: SnapshotConfig) => ({
    ticketLabel: serviceCloudIntegrationEnabled
      ? 'Open Cases'
      : config.baseTicketLabel,
    percentLabel: serviceCloudIntegrationEnabled
      ? 'Percent of assets with 1+ open cases'
      : config.basePercentLabel,
    ticketTooltip: serviceCloudIntegrationEnabled ? null : config.toolTip,
  })

  return (
    <PageBody bgColor="gray">
      <header className="tw-mb-8 tw-flex tw-items-center tw-justify-between">
        <div>
          <div className="tw-mb-3 tw-font-semibold">Open Service Activity</div>
          <div className="tw-text-gray-500">
            The following views show all open service activity (
            {objectName.toLowerCase()}s).
          </div>
        </div>
        <DataRefreshMessage />
      </header>

      <div className="tw-mb-8 tw-flex tw-w-full tw-justify-between tw-gap-4">
        {SNAPSHOTS_CONFIG.map(config => {
          const { ticketLabel, percentLabel, ticketTooltip } =
            getServiceCloudLabels(config)
          return (
            <div key={config.stage} className="tw-flex-1">
              <SnapshotCard
                snapshot={snapshots[config.stage]}
                icon={<img alt="icon" src={config.icon} />}
                description={config.description}
                ticketLabel={ticketLabel}
                ticketTooltip={ticketTooltip}
                percentLabel={percentLabel}
                showAdditionalFields={config.showAdditionalFields}
              />
            </div>
          )
        })}
      </div>

      <PageCard>
        <ServiceActivityCsvContextProvider
          pageName={PageName.ServiceActivity}
          tableModel={tableModel}
          csvOptions={{
            columns: exportCsvColumns,
            fileName: datedCsvFileName('service_activity'),
          }}
        >
          <ServiceActivityTable
            tableModel={tableModel}
            isLoading={isLoading}
            isError={isError}
            filterDefs={filterDefs}
            handleClickRow={handleClickRow}
          />
        </ServiceActivityCsvContextProvider>

        {selectedServiceActivityId && (
          <ResidentialServiceActivityDrawer
            serviceActivityId={selectedServiceActivityId}
            onClose={handleCloseDrawer}
            onChangeServiceActivityId={setSelectedServiceActivityId}
          />
        )}
      </PageCard>
    </PageBody>
  )
}
