/* tslint:disable */
/* eslint-disable */
/**
 * client-portal
 * API supporting the client portal web app.
 *
 * The version of the OpenAPI document: 1.1.140-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postalCode': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AssetHealthStatus = {
    ZeroGen: 'ZeroGen',
    NonCom: 'NonCom',
    Underproducing: 'Underproducing',
    Healthy: 'Healthy'
} as const;

export type AssetHealthStatus = typeof AssetHealthStatus[keyof typeof AssetHealthStatus];


/**
 * 
 * @export
 * @interface AssetManager
 */
export interface AssetManager {
    /**
     * 
     * @type {string}
     * @memberof AssetManager
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AssetMetadata
 */
export interface AssetMetadata {
    /**
     * 
     * @type {number}
     * @memberof AssetMetadata
     */
    'assetId': number;
    /**
     * 
     * @type {string}
     * @memberof AssetMetadata
     */
    'assetName': string;
    /**
     * 
     * @type {number}
     * @memberof AssetMetadata
     */
    'systemSize': number | null;
    /**
     * 
     * @type {Address}
     * @memberof AssetMetadata
     */
    'address': Address;
    /**
     * 
     * @type {AssetStage}
     * @memberof AssetMetadata
     */
    'assetStage': AssetStage | null;
    /**
     * 
     * @type {AssetHealthStatus}
     * @memberof AssetMetadata
     */
    'healthStatus': AssetHealthStatus | null;
    /**
     * 
     * @type {string}
     * @memberof AssetMetadata
     */
    'clientAssetId': string | null;
    /**
     * 
     * @type {HomeOwner}
     * @memberof AssetMetadata
     */
    'homeOwner': HomeOwner;
    /**
     * 
     * @type {string}
     * @memberof AssetMetadata
     */
    'installerName': string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetMetadata
     */
    'ptoDate': string | null;
}


/**
 * 
 * @export
 * @interface AssetPerformanceRow
 */
export interface AssetPerformanceRow {
    /**
     * 
     * @type {number}
     * @memberof AssetPerformanceRow
     */
    'assetId': number;
    /**
     * 
     * @type {string}
     * @memberof AssetPerformanceRow
     */
    'assetManagerName': string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetPerformanceRow
     */
    'assetName': string;
    /**
     * 
     * @type {number}
     * @memberof AssetPerformanceRow
     */
    'systemSize': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetPerformanceRow
     */
    'actualEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetPerformanceRow
     */
    'predictedEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetPerformanceRow
     */
    'expectedEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetPerformanceRow
     */
    'actualVsPredictedEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetPerformanceRow
     */
    'actualVsExpectedEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetPerformanceRow
     */
    'solarResourceRatio': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetPerformanceRow
     */
    'openServiceActivityCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof AssetPerformanceRow
     */
    'isModeled': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AssetStage = {
    MetadataVerification: 'MetadataVerification',
    Pvt: 'PVT',
    Active: 'Active'
} as const;

export type AssetStage = typeof AssetStage[keyof typeof AssetStage];


/**
 * 
 * @export
 * @interface BaseServiceActivity
 */
export interface BaseServiceActivity {
    /**
     * 
     * @type {string}
     * @memberof BaseServiceActivity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BaseServiceActivity
     */
    'caseNumber': string | null;
    /**
     * 
     * @type {number}
     * @memberof BaseServiceActivity
     */
    'assetId': number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseServiceActivity
     */
    'subject': string | null;
    /**
     * 
     * @type {Priority}
     * @memberof BaseServiceActivity
     */
    'priority': Priority | null;
    /**
     * 
     * @type {string}
     * @memberof BaseServiceActivity
     */
    'creationReason': string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseServiceActivity
     */
    'status': string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseServiceActivity
     */
    'subStatus': string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseServiceActivity
     */
    'summary': string | null;
    /**
     * 
     * @type {number}
     * @memberof BaseServiceActivity
     */
    'workOrdersCount': number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseServiceActivity
     */
    'scheduleDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseServiceActivity
     */
    'closedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseServiceActivity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BaseServiceActivity
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseServiceActivity
     */
    'pendingClientAction'?: boolean | null;
}


/**
 * 
 * @export
 * @interface Branding
 */
export interface Branding {
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'logoUrl': string;
}
/**
 * 
 * @export
 * @interface Case
 */
export interface Case {
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'caseNumber': string;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    'assetId': number | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'subject': string | null;
    /**
     * 
     * @type {Priority}
     * @memberof Case
     */
    'priority': Priority | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'creationReason': string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'status': string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'closedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Case
     */
    'pendingClientAction'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    'workOrderCount': number | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'origin': string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'lastPublicOutreachDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'resolutionCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'customerUnresponsiveDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'summary': string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'rmaCaseNumber': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Case
     */
    'isEscalated': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Case
     */
    'isNonCoveredRequest': boolean | null;
    /**
     * 
     * @type {TroubleshootingType}
     * @memberof Case
     */
    'troubleshootingType': TroubleshootingType | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    'caseType': string | null;
}


/**
 * 
 * @export
 * @interface CommercialServiceActivity
 */
export interface CommercialServiceActivity {
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'caseNumber': string | null;
    /**
     * 
     * @type {number}
     * @memberof CommercialServiceActivity
     */
    'assetId': number | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'subject': string | null;
    /**
     * 
     * @type {Priority}
     * @memberof CommercialServiceActivity
     */
    'priority': Priority | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'creationReason': string | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'status': string | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'subStatus': string | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'summary': string | null;
    /**
     * 
     * @type {number}
     * @memberof CommercialServiceActivity
     */
    'workOrdersCount': number | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'scheduleDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'closedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialServiceActivity
     */
    'pendingClientAction'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'assetName': string | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialServiceActivity
     */
    'assetManagerName': string | null;
}


/**
 * 
 * @export
 * @interface DailyPerformanceRow
 */
export interface DailyPerformanceRow {
    /**
     * 
     * @type {string}
     * @memberof DailyPerformanceRow
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof DailyPerformanceRow
     */
    'actualEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyPerformanceRow
     */
    'expectedEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyPerformanceRow
     */
    'actualVsExpectedEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyPerformanceRow
     */
    'actualVsExpectedEnergy7Day': number | null;
}
/**
 * 
 * @export
 * @interface FeatureFlags
 */
export interface FeatureFlags {
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'isResidentialClient': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'assetDetailEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'assetPerformanceEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'portfolioOverviewEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'serviceActivityEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'serviceCloudIntegrationEnabled': boolean;
}
/**
 * 
 * @export
 * @interface GetAssetManagersResponse
 */
export interface GetAssetManagersResponse {
    /**
     * 
     * @type {Array<AssetManager>}
     * @memberof GetAssetManagersResponse
     */
    'managers': Array<AssetManager>;
}
/**
 * 
 * @export
 * @interface GetAssetsPerformanceResponse
 */
export interface GetAssetsPerformanceResponse {
    /**
     * 
     * @type {Array<AssetPerformanceRow>}
     * @memberof GetAssetsPerformanceResponse
     */
    'data': Array<AssetPerformanceRow>;
}
/**
 * 
 * @export
 * @interface GetBrandingResponse
 */
export interface GetBrandingResponse {
    /**
     * 
     * @type {Branding}
     * @memberof GetBrandingResponse
     */
    'branding': Branding | null;
}
/**
 * 
 * @export
 * @interface GetClientProfileIdsResponse
 */
export interface GetClientProfileIdsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetClientProfileIdsResponse
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface GetCommercialServiceActivityResponse
 */
export interface GetCommercialServiceActivityResponse {
    /**
     * 
     * @type {Array<CommercialServiceActivity>}
     * @memberof GetCommercialServiceActivityResponse
     */
    'data': Array<CommercialServiceActivity>;
}
/**
 * 
 * @export
 * @interface GetDailyPerformanceResponse
 */
export interface GetDailyPerformanceResponse {
    /**
     * 
     * @type {Array<DailyPerformanceRow>}
     * @memberof GetDailyPerformanceResponse
     */
    'data': Array<DailyPerformanceRow>;
}
/**
 * 
 * @export
 * @interface GetMonthlyPerformanceResponse
 */
export interface GetMonthlyPerformanceResponse {
    /**
     * 
     * @type {Array<MonthlyPerformanceRow>}
     * @memberof GetMonthlyPerformanceResponse
     */
    'data': Array<MonthlyPerformanceRow>;
}
/**
 * 
 * @export
 * @interface GetResidentialAssetServiceActivityResponse
 */
export interface GetResidentialAssetServiceActivityResponse {
    /**
     * 
     * @type {Array<ResidentialServiceActivity>}
     * @memberof GetResidentialAssetServiceActivityResponse
     */
    'data': Array<ResidentialServiceActivity>;
}
/**
 * 
 * @export
 * @interface GetResidentialServiceActivityResponse
 */
export interface GetResidentialServiceActivityResponse {
    /**
     * 
     * @type {Array<ResidentialServiceActivity>}
     * @memberof GetResidentialServiceActivityResponse
     */
    'serviceActivity': Array<ResidentialServiceActivity>;
    /**
     * 
     * @type {Array<ServiceActivitySnapshot>}
     * @memberof GetResidentialServiceActivityResponse
     */
    'snapshots': Array<ServiceActivitySnapshot>;
}
/**
 * 
 * @export
 * @interface GetWorkOrdersForCaseResponse
 */
export interface GetWorkOrdersForCaseResponse {
    /**
     * 
     * @type {Array<WorkOrder>}
     * @memberof GetWorkOrdersForCaseResponse
     */
    'data': Array<WorkOrder>;
}
/**
 * 
 * @export
 * @interface HomeOwner
 */
export interface HomeOwner {
    /**
     * 
     * @type {string}
     * @memberof HomeOwner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HomeOwner
     */
    'phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeOwner
     */
    'email': string | null;
}
/**
 * 
 * @export
 * @interface MonthlyPerformanceRow
 */
export interface MonthlyPerformanceRow {
    /**
     * 
     * @type {string}
     * @memberof MonthlyPerformanceRow
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyPerformanceRow
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPerformanceRow
     */
    'actualEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPerformanceRow
     */
    'predictedEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPerformanceRow
     */
    'expectedEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPerformanceRow
     */
    'actualVsPredictedEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPerformanceRow
     */
    'actualVsExpectedEnergy': number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthlyPerformanceRow
     */
    'solarResourceRatio': number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PageName = {
    PortfolioOverview: 'PortfolioOverview',
    ServiceActivity: 'ServiceActivity',
    Asset: 'Asset'
} as const;

export type PageName = typeof PageName[keyof typeof PageName];


/**
 * 
 * @export
 * @enum {string}
 */

export const Priority = {
    Urgent: 'Urgent',
    High: 'High',
    Normal: 'Normal',
    Low: 'Low'
} as const;

export type Priority = typeof Priority[keyof typeof Priority];


/**
 * 
 * @export
 * @interface ResidentialServiceActivity
 */
export interface ResidentialServiceActivity {
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'caseNumber': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResidentialServiceActivity
     */
    'assetId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'subject': string | null;
    /**
     * 
     * @type {Priority}
     * @memberof ResidentialServiceActivity
     */
    'priority': Priority | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'creationReason': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'status': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'subStatus': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'summary': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResidentialServiceActivity
     */
    'workOrdersCount': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'scheduleDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'closedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentialServiceActivity
     */
    'pendingClientAction'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'type': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'customerName': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResidentialServiceActivity
     */
    'otherOpenServiceActivitiesCount': number;
    /**
     * 
     * @type {number}
     * @memberof ResidentialServiceActivity
     */
    'formId': number | null;
    /**
     * 
     * @type {AssetStage}
     * @memberof ResidentialServiceActivity
     */
    'assetStage': AssetStage | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivity
     */
    'clientAssetId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentialServiceActivity
     */
    'isEscalated': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentialServiceActivity
     */
    'isNonCoveredRequest': boolean | null;
    /**
     * 
     * @type {TroubleshootingType}
     * @memberof ResidentialServiceActivity
     */
    'troubleshootingType': TroubleshootingType | null;
}


/**
 * 
 * @export
 * @interface ResidentialServiceActivityDetail
 */
export interface ResidentialServiceActivityDetail {
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'caseNumber': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResidentialServiceActivityDetail
     */
    'assetId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'subject': string | null;
    /**
     * 
     * @type {Priority}
     * @memberof ResidentialServiceActivityDetail
     */
    'priority': Priority | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'creationReason': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'status': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'subStatus': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'summary': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResidentialServiceActivityDetail
     */
    'workOrdersCount': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'scheduleDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'closedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentialServiceActivityDetail
     */
    'pendingClientAction'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'type': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'customerName': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResidentialServiceActivityDetail
     */
    'otherOpenServiceActivitiesCount': number;
    /**
     * 
     * @type {number}
     * @memberof ResidentialServiceActivityDetail
     */
    'formId': number | null;
    /**
     * 
     * @type {AssetStage}
     * @memberof ResidentialServiceActivityDetail
     */
    'assetStage': AssetStage | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'clientAssetId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentialServiceActivityDetail
     */
    'isEscalated': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentialServiceActivityDetail
     */
    'isNonCoveredRequest': boolean | null;
    /**
     * 
     * @type {TroubleshootingType}
     * @memberof ResidentialServiceActivityDetail
     */
    'troubleshootingType': TroubleshootingType | null;
    /**
     * 
     * @type {string}
     * @memberof ResidentialServiceActivityDetail
     */
    'fieldServiceStatus': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResidentialServiceActivityDetail
     */
    'relatedServiceActivityIds': Array<string>;
    /**
     * 
     * @type {ServiceActivityQuote}
     * @memberof ResidentialServiceActivityDetail
     */
    'quote': ServiceActivityQuote | null;
}


/**
 * 
 * @export
 * @interface SPAAppendLogRequest
 */
export interface SPAAppendLogRequest {
    /**
     * 
     * @type {Array<SPALogEntry>}
     * @memberof SPAAppendLogRequest
     */
    'entries': Array<SPALogEntry>;
}
/**
 * 
 * @export
 * @interface SPAConfig
 */
export interface SPAConfig {
    /**
     * 
     * @type {string}
     * @memberof SPAConfig
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof SPAConfig
     */
    'issuerUri': string;
}
/**
 * 
 * @export
 * @interface SPALogEntry
 */
export interface SPALogEntry {
    /**
     * 
     * @type {string}
     * @memberof SPALogEntry
     */
    'level': SPALogEntryLevelEnum;
    /**
     * 
     * @type {object}
     * @memberof SPALogEntry
     */
    'data': object;
}

export const SPALogEntryLevelEnum = {
    Error: 'ERROR',
    Warn: 'WARN',
    Info: 'INFO',
    Debug: 'DEBUG'
} as const;

export type SPALogEntryLevelEnum = typeof SPALogEntryLevelEnum[keyof typeof SPALogEntryLevelEnum];

/**
 * 
 * @export
 * @interface ServiceActivityAgeBucket
 */
export interface ServiceActivityAgeBucket {
    /**
     * 
     * @type {string}
     * @memberof ServiceActivityAgeBucket
     */
    'id': ServiceActivityAgeBucketIdEnum;
    /**
     * 
     * @type {number}
     * @memberof ServiceActivityAgeBucket
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActivityAgeBucket
     */
    'percentage': number;
}

export const ServiceActivityAgeBucketIdEnum = {
    New0To29Days: 'New0To29Days',
    Aging30To59Days: 'Aging30To59Days',
    Aging60To89Days: 'Aging60To89Days',
    Aging90PlusDays: 'Aging90PlusDays'
} as const;

export type ServiceActivityAgeBucketIdEnum = typeof ServiceActivityAgeBucketIdEnum[keyof typeof ServiceActivityAgeBucketIdEnum];

/**
 * 
 * @export
 * @interface ServiceActivityExportRequest
 */
export interface ServiceActivityExportRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceActivityExportRequest
     */
    'caseIds': Array<string>;
    /**
     * The IANA zone id to use when formatting date times in the export
     * @type {string}
     * @memberof ServiceActivityExportRequest
     */
    'exportZoneId': string;
    /**
     * 
     * @type {PageName}
     * @memberof ServiceActivityExportRequest
     */
    'pageName': PageName;
}


/**
 * 
 * @export
 * @interface ServiceActivityQuote
 */
export interface ServiceActivityQuote {
    /**
     * 
     * @type {string}
     * @memberof ServiceActivityQuote
     */
    'laborCost': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceActivityQuote
     */
    'equipmentCost': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceActivityQuote
     */
    'materialCost': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceActivityQuote
     */
    'adderCost': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceActivityQuote
     */
    'marginCost': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceActivityQuote
     */
    'marginPercentage': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceActivityQuote
     */
    'totalCost': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceActivityQuote
     */
    'expirationDate': string | null;
}
/**
 * 
 * @export
 * @interface ServiceActivitySnapshot
 */
export interface ServiceActivitySnapshot {
    /**
     * 
     * @type {AssetStage}
     * @memberof ServiceActivitySnapshot
     */
    'assetStage'?: AssetStage | null;
    /**
     * 
     * @type {number}
     * @memberof ServiceActivitySnapshot
     */
    'countOfAssets': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActivitySnapshot
     */
    'countOfServiceActivitiesOpenedForCategory'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActivitySnapshot
     */
    'percentageOfAssetsWithServiceActivityOpenedForCategory'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActivitySnapshot
     */
    'countOfPendingClientActions': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActivitySnapshot
     */
    'countOfNonCoveredServiceRequests': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActivitySnapshot
     */
    'countOfRemoteTroubleShootings': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActivitySnapshot
     */
    'countOfFieldServices': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActivitySnapshot
     */
    'countOfEscalations': number;
    /**
     * 
     * @type {Array<ServiceActivityAgeBucket>}
     * @memberof ServiceActivitySnapshot
     */
    'ageBreakdown': Array<ServiceActivityAgeBucket>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TroubleshootingType = {
    Remote: 'Remote',
    FieldService: 'FieldService'
} as const;

export type TroubleshootingType = typeof TroubleshootingType[keyof typeof TroubleshootingType];


/**
 * 
 * @export
 * @interface WorkOrder
 */
export interface WorkOrder {
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'workOrderNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'caseId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'subject': string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'subStatus': string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'summary': string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'scheduleDate': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkOrder
     */
    'pendingClientAction': boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'rmaCaseNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'resolutionCode': string | null;
    /**
     * 
     * @type {WorkOrderQuoteDetails}
     * @memberof WorkOrder
     */
    'quoteDetails': WorkOrderQuoteDetails | null;
}
/**
 * 
 * @export
 * @interface WorkOrderQuote
 */
export interface WorkOrderQuote {
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'workOrderId': string;
    /**
     * 
     * @type {WorkOrderQuoteStatusEnum}
     * @memberof WorkOrderQuote
     */
    'status'?: WorkOrderQuoteStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'laborCost': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'equipmentCost': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'mobilizationCost': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'travelCost': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'materialCost': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'otherCost': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'taxCost': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'clientTotalCost': string;
    /**
     * 0.2 = 20%, etc.
     * @type {number}
     * @memberof WorkOrderQuote
     */
    'omnidianMarkupPercentage': number;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'omnidianMarkupAmount': string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuote
     */
    'expirationDate': string | null;
}


/**
 * 
 * @export
 * @interface WorkOrderQuoteDetails
 */
export interface WorkOrderQuoteDetails {
    /**
     * 
     * @type {string}
     * @memberof WorkOrderQuoteDetails
     */
    'billableParty'?: WorkOrderQuoteDetailsBillablePartyEnum;
    /**
     * 
     * @type {WorkOrderQuoteStatusEnum}
     * @memberof WorkOrderQuoteDetails
     */
    'aggregateQuoteStatus'?: WorkOrderQuoteStatusEnum;
    /**
     * 
     * @type {Array<WorkOrderQuote>}
     * @memberof WorkOrderQuoteDetails
     */
    'quotes'?: Array<WorkOrderQuote>;
}

export const WorkOrderQuoteDetailsBillablePartyEnum = {
    Split: 'Split',
    Client: 'Client'
} as const;

export type WorkOrderQuoteDetailsBillablePartyEnum = typeof WorkOrderQuoteDetailsBillablePartyEnum[keyof typeof WorkOrderQuoteDetailsBillablePartyEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const WorkOrderQuoteStatusEnum = {
    PendingClientDecision: 'PendingClientDecision',
    PendingCustomerAndClientDecision: 'PendingCustomerAndClientDecision',
    Approved: 'Approved',
    Denied: 'Denied'
} as const;

export type WorkOrderQuoteStatusEnum = typeof WorkOrderQuoteStatusEnum[keyof typeof WorkOrderQuoteStatusEnum];



/**
 * ApiControllerApi - axios parameter creator
 * @export
 */
export const ApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the asset manager information for the user\'s client.
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetManagers: async (xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/asset-managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get summary performance data for all the client\'s assets in the specified date range
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetsPerformance: async (startDate: string, endDate: string, xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAssetsPerformance', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAssetsPerformance', 'endDate', endDate)
            const localVarPath = `/api/assets-performance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the branding information for the user\'s client.
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranding: async (xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/branding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiControllerApi - functional programming interface
 * @export
 */
export const ApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the asset manager information for the user\'s client.
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetManagers(xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAssetManagersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetManagers(xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApiControllerApi.getAssetManagers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get summary performance data for all the client\'s assets in the specified date range
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetsPerformance(startDate: string, endDate: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAssetsPerformanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetsPerformance(startDate, endDate, xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApiControllerApi.getAssetsPerformance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the branding information for the user\'s client.
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranding(xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBrandingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranding(xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApiControllerApi.getBranding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ApiControllerApi - factory interface
 * @export
 */
export const ApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the asset manager information for the user\'s client.
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetManagers(xOmnidianClientProfileId?: string, options?: any): AxiosPromise<GetAssetManagersResponse> {
            return localVarFp.getAssetManagers(xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get summary performance data for all the client\'s assets in the specified date range
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetsPerformance(startDate: string, endDate: string, xOmnidianClientProfileId?: string, options?: any): AxiosPromise<GetAssetsPerformanceResponse> {
            return localVarFp.getAssetsPerformance(startDate, endDate, xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the branding information for the user\'s client.
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranding(xOmnidianClientProfileId?: string, options?: any): AxiosPromise<GetBrandingResponse> {
            return localVarFp.getBranding(xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiControllerApi - object-oriented interface
 * @export
 * @class ApiControllerApi
 * @extends {BaseAPI}
 */
export class ApiControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get the asset manager information for the user\'s client.
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiControllerApi
     */
    public getAssetManagers(xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return ApiControllerApiFp(this.configuration).getAssetManagers(xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get summary performance data for all the client\'s assets in the specified date range
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiControllerApi
     */
    public getAssetsPerformance(startDate: string, endDate: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return ApiControllerApiFp(this.configuration).getAssetsPerformance(startDate, endDate, xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the branding information for the user\'s client.
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiControllerApi
     */
    public getBranding(xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return ApiControllerApiFp(this.configuration).getBranding(xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssetControllerApi - axios parameter creator
 * @export
 */
export const AssetControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get metadata information for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetMetadata: async (assetId: number, xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAssetMetadata', 'assetId', assetId)
            const localVarPath = `/api/assets/{assetId}/metadata`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get daily performance data for an asset
         * @param {number} assetId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetPerformanceDaily: async (assetId: number, startDate: string, endDate: string, xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAssetPerformanceDaily', 'assetId', assetId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAssetPerformanceDaily', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAssetPerformanceDaily', 'endDate', endDate)
            const localVarPath = `/api/assets/{assetId}/performance/daily`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get monthly performance data for an asset
         * @param {number} assetId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetPerformanceMonthly: async (assetId: number, startDate: string, endDate: string, xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAssetPerformanceMonthly', 'assetId', assetId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAssetPerformanceMonthly', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAssetPerformanceMonthly', 'endDate', endDate)
            const localVarPath = `/api/assets/{assetId}/performance/monthly`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the active tickets for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAssetServiceActivity: async (assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAssetServiceActivity', 'assetId', assetId)
            const localVarPath = `/api/assets/{assetId}/service-activity`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeClosed !== undefined) {
                localVarQueryParameter['includeClosed'] = includeClosed;
            }

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the active tickets for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommercialAssetServiceActivity: async (assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getCommercialAssetServiceActivity', 'assetId', assetId)
            const localVarPath = `/api/assets/{assetId}/service-activity/commercial`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeClosed !== undefined) {
                localVarQueryParameter['includeClosed'] = includeClosed;
            }

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the active tickets for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentialAssetServiceActivity: async (assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getResidentialAssetServiceActivity', 'assetId', assetId)
            const localVarPath = `/api/assets/{assetId}/service-activity/residential`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeClosed !== undefined) {
                localVarQueryParameter['includeClosed'] = includeClosed;
            }

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetControllerApi - functional programming interface
 * @export
 */
export const AssetControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get metadata information for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetMetadata(assetId: number, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetMetadata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetMetadata(assetId, xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetControllerApi.getAssetMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get daily performance data for an asset
         * @param {number} assetId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetPerformanceDaily(assetId: number, startDate: string, endDate: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDailyPerformanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetPerformanceDaily(assetId, startDate, endDate, xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetControllerApi.getAssetPerformanceDaily']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get monthly performance data for an asset
         * @param {number} assetId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetPerformanceMonthly(assetId: number, startDate: string, endDate: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMonthlyPerformanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetPerformanceMonthly(assetId, startDate, endDate, xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetControllerApi.getAssetPerformanceMonthly']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the active tickets for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAssetServiceActivity(assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommercialServiceActivityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetServiceActivity(assetId, xOmnidianClientProfileId, includeClosed, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetControllerApi.getAssetServiceActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the active tickets for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommercialAssetServiceActivity(assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommercialServiceActivityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommercialAssetServiceActivity(assetId, xOmnidianClientProfileId, includeClosed, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetControllerApi.getCommercialAssetServiceActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the active tickets for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResidentialAssetServiceActivity(assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetResidentialAssetServiceActivityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResidentialAssetServiceActivity(assetId, xOmnidianClientProfileId, includeClosed, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetControllerApi.getResidentialAssetServiceActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetControllerApi - factory interface
 * @export
 */
export const AssetControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get metadata information for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetMetadata(assetId: number, xOmnidianClientProfileId?: string, options?: any): AxiosPromise<AssetMetadata> {
            return localVarFp.getAssetMetadata(assetId, xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get daily performance data for an asset
         * @param {number} assetId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetPerformanceDaily(assetId: number, startDate: string, endDate: string, xOmnidianClientProfileId?: string, options?: any): AxiosPromise<GetDailyPerformanceResponse> {
            return localVarFp.getAssetPerformanceDaily(assetId, startDate, endDate, xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get monthly performance data for an asset
         * @param {number} assetId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetPerformanceMonthly(assetId: number, startDate: string, endDate: string, xOmnidianClientProfileId?: string, options?: any): AxiosPromise<GetMonthlyPerformanceResponse> {
            return localVarFp.getAssetPerformanceMonthly(assetId, startDate, endDate, xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the active tickets for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAssetServiceActivity(assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options?: any): AxiosPromise<GetCommercialServiceActivityResponse> {
            return localVarFp.getAssetServiceActivity(assetId, xOmnidianClientProfileId, includeClosed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the active tickets for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommercialAssetServiceActivity(assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options?: any): AxiosPromise<GetCommercialServiceActivityResponse> {
            return localVarFp.getCommercialAssetServiceActivity(assetId, xOmnidianClientProfileId, includeClosed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the active tickets for an asset
         * @param {number} assetId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentialAssetServiceActivity(assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options?: any): AxiosPromise<GetResidentialAssetServiceActivityResponse> {
            return localVarFp.getResidentialAssetServiceActivity(assetId, xOmnidianClientProfileId, includeClosed, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetControllerApi - object-oriented interface
 * @export
 * @class AssetControllerApi
 * @extends {BaseAPI}
 */
export class AssetControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get metadata information for an asset
     * @param {number} assetId 
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAssetMetadata(assetId: number, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAssetMetadata(assetId, xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get daily performance data for an asset
     * @param {number} assetId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAssetPerformanceDaily(assetId: number, startDate: string, endDate: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAssetPerformanceDaily(assetId, startDate, endDate, xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get monthly performance data for an asset
     * @param {number} assetId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAssetPerformanceMonthly(assetId: number, startDate: string, endDate: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAssetPerformanceMonthly(assetId, startDate, endDate, xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the active tickets for an asset
     * @param {number} assetId 
     * @param {string} [xOmnidianClientProfileId] 
     * @param {boolean} [includeClosed] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAssetServiceActivity(assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options?: RawAxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAssetServiceActivity(assetId, xOmnidianClientProfileId, includeClosed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the active tickets for an asset
     * @param {number} assetId 
     * @param {string} [xOmnidianClientProfileId] 
     * @param {boolean} [includeClosed] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getCommercialAssetServiceActivity(assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options?: RawAxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getCommercialAssetServiceActivity(assetId, xOmnidianClientProfileId, includeClosed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the active tickets for an asset
     * @param {number} assetId 
     * @param {string} [xOmnidianClientProfileId] 
     * @param {boolean} [includeClosed] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getResidentialAssetServiceActivity(assetId: number, xOmnidianClientProfileId?: string, includeClosed?: boolean, options?: RawAxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getResidentialAssetServiceActivity(assetId, xOmnidianClientProfileId, includeClosed, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CaseControllerApi - axios parameter creator
 * @export
 */
export const CaseControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the details of a case
         * @param {string} caseId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaseById: async (caseId: string, xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('getCaseById', 'caseId', caseId)
            const localVarPath = `/api/cases/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of work orders for a case
         * @param {string} caseId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkOrdersForCase: async (caseId: string, xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('getWorkOrdersForCase', 'caseId', caseId)
            const localVarPath = `/api/cases/{caseId}/work-orders`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaseControllerApi - functional programming interface
 * @export
 */
export const CaseControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaseControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the details of a case
         * @param {string} caseId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCaseById(caseId: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Case>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCaseById(caseId, xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CaseControllerApi.getCaseById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the list of work orders for a case
         * @param {string} caseId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkOrdersForCase(caseId: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkOrdersForCaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkOrdersForCase(caseId, xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CaseControllerApi.getWorkOrdersForCase']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CaseControllerApi - factory interface
 * @export
 */
export const CaseControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaseControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the details of a case
         * @param {string} caseId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaseById(caseId: string, xOmnidianClientProfileId?: string, options?: any): AxiosPromise<Case> {
            return localVarFp.getCaseById(caseId, xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list of work orders for a case
         * @param {string} caseId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkOrdersForCase(caseId: string, xOmnidianClientProfileId?: string, options?: any): AxiosPromise<GetWorkOrdersForCaseResponse> {
            return localVarFp.getWorkOrdersForCase(caseId, xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaseControllerApi - object-oriented interface
 * @export
 * @class CaseControllerApi
 * @extends {BaseAPI}
 */
export class CaseControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get the details of a case
     * @param {string} caseId 
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseControllerApi
     */
    public getCaseById(caseId: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return CaseControllerApiFp(this.configuration).getCaseById(caseId, xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list of work orders for a case
     * @param {string} caseId 
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseControllerApi
     */
    public getWorkOrdersForCase(caseId: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return CaseControllerApiFp(this.configuration).getWorkOrdersForCase(caseId, xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServiceActivityControllerApi - axios parameter creator
 * @export
 */
export const ServiceActivityControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all open service commercial activity for the client
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommercialServiceActivity: async (xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/service-activity/commercial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all open residential service activity for the client
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentialServiceActivity: async (xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/service-activity/residential`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details for a specific residential service activity
         * @param {string} serviceActivityId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentialServiceActivityById: async (serviceActivityId: string, xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceActivityId' is not null or undefined
            assertParamExists('getResidentialServiceActivityById', 'serviceActivityId', serviceActivityId)
            const localVarPath = `/api/service-activity/residential/{serviceActivityId}`
                .replace(`{${"serviceActivityId"}}`, encodeURIComponent(String(serviceActivityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceActivityControllerApi - functional programming interface
 * @export
 */
export const ServiceActivityControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceActivityControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all open service commercial activity for the client
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommercialServiceActivity(xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommercialServiceActivityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommercialServiceActivity(xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceActivityControllerApi.getCommercialServiceActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all open residential service activity for the client
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResidentialServiceActivity(xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetResidentialServiceActivityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResidentialServiceActivity(xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceActivityControllerApi.getResidentialServiceActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the details for a specific residential service activity
         * @param {string} serviceActivityId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResidentialServiceActivityById(serviceActivityId: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResidentialServiceActivityDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResidentialServiceActivityById(serviceActivityId, xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceActivityControllerApi.getResidentialServiceActivityById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ServiceActivityControllerApi - factory interface
 * @export
 */
export const ServiceActivityControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceActivityControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all open service commercial activity for the client
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommercialServiceActivity(xOmnidianClientProfileId?: string, options?: any): AxiosPromise<GetCommercialServiceActivityResponse> {
            return localVarFp.getCommercialServiceActivity(xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all open residential service activity for the client
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentialServiceActivity(xOmnidianClientProfileId?: string, options?: any): AxiosPromise<GetResidentialServiceActivityResponse> {
            return localVarFp.getResidentialServiceActivity(xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details for a specific residential service activity
         * @param {string} serviceActivityId 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentialServiceActivityById(serviceActivityId: string, xOmnidianClientProfileId?: string, options?: any): AxiosPromise<ResidentialServiceActivityDetail> {
            return localVarFp.getResidentialServiceActivityById(serviceActivityId, xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceActivityControllerApi - object-oriented interface
 * @export
 * @class ServiceActivityControllerApi
 * @extends {BaseAPI}
 */
export class ServiceActivityControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get all open service commercial activity for the client
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceActivityControllerApi
     */
    public getCommercialServiceActivity(xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return ServiceActivityControllerApiFp(this.configuration).getCommercialServiceActivity(xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all open residential service activity for the client
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceActivityControllerApi
     */
    public getResidentialServiceActivity(xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return ServiceActivityControllerApiFp(this.configuration).getResidentialServiceActivity(xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details for a specific residential service activity
     * @param {string} serviceActivityId 
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceActivityControllerApi
     */
    public getResidentialServiceActivityById(serviceActivityId: string, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return ServiceActivityControllerApiFp(this.configuration).getResidentialServiceActivityById(serviceActivityId, xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServiceActivityExportControllerApi - axios parameter creator
 * @export
 */
export const ServiceActivityExportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download a CSV export of service activity data
         * @param {ServiceActivityExportRequest} serviceActivityExportRequest 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadServiceActivityCsv: async (serviceActivityExportRequest: ServiceActivityExportRequest, xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceActivityExportRequest' is not null or undefined
            assertParamExists('downloadServiceActivityCsv', 'serviceActivityExportRequest', serviceActivityExportRequest)
            const localVarPath = `/api/service-activity/export/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceActivityExportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceActivityExportControllerApi - functional programming interface
 * @export
 */
export const ServiceActivityExportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceActivityExportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download a CSV export of service activity data
         * @param {ServiceActivityExportRequest} serviceActivityExportRequest 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadServiceActivityCsv(serviceActivityExportRequest: ServiceActivityExportRequest, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadServiceActivityCsv(serviceActivityExportRequest, xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceActivityExportControllerApi.downloadServiceActivityCsv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ServiceActivityExportControllerApi - factory interface
 * @export
 */
export const ServiceActivityExportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceActivityExportControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Download a CSV export of service activity data
         * @param {ServiceActivityExportRequest} serviceActivityExportRequest 
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadServiceActivityCsv(serviceActivityExportRequest: ServiceActivityExportRequest, xOmnidianClientProfileId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadServiceActivityCsv(serviceActivityExportRequest, xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceActivityExportControllerApi - object-oriented interface
 * @export
 * @class ServiceActivityExportControllerApi
 * @extends {BaseAPI}
 */
export class ServiceActivityExportControllerApi extends BaseAPI {
    /**
     * 
     * @summary Download a CSV export of service activity data
     * @param {ServiceActivityExportRequest} serviceActivityExportRequest 
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceActivityExportControllerApi
     */
    public downloadServiceActivityCsv(serviceActivityExportRequest: ServiceActivityExportRequest, xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return ServiceActivityExportControllerApiFp(this.configuration).downloadServiceActivityCsv(serviceActivityExportRequest, xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SpaControllerApi - axios parameter creator
 * @export
 */
export const SpaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Record one or more log records from the SPA.
         * @param {SPAAppendLogRequest} sPAAppendLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendLog: async (sPAAppendLogRequest: SPAAppendLogRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sPAAppendLogRequest' is not null or undefined
            assertParamExists('appendLog', 'sPAAppendLogRequest', sPAAppendLogRequest)
            const localVarPath = `/spa/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sPAAppendLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of profiles available for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientProfileIds: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spa/client-profile-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information necessary to configure the SPA.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spa/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Feature flag settings for the SPA.
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlags: async (xOmnidianClientProfileId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spa/flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xOmnidianClientProfileId != null) {
                localVarHeaderParameter['X-Omnidian-Client-Profile-Id'] = String(xOmnidianClientProfileId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpaControllerApi - functional programming interface
 * @export
 */
export const SpaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Record one or more log records from the SPA.
         * @param {SPAAppendLogRequest} sPAAppendLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appendLog(sPAAppendLogRequest: SPAAppendLogRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appendLog(sPAAppendLogRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpaControllerApi.appendLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve the list of profiles available for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientProfileIds(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientProfileIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientProfileIds(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpaControllerApi.getClientProfileIds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get information necessary to configure the SPA.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfig(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SPAConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfig(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpaControllerApi.getConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Feature flag settings for the SPA.
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlags(xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureFlags>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlags(xOmnidianClientProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpaControllerApi.getFlags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SpaControllerApi - factory interface
 * @export
 */
export const SpaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpaControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Record one or more log records from the SPA.
         * @param {SPAAppendLogRequest} sPAAppendLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendLog(sPAAppendLogRequest: SPAAppendLogRequest, options?: any): AxiosPromise<void> {
            return localVarFp.appendLog(sPAAppendLogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the list of profiles available for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientProfileIds(options?: any): AxiosPromise<GetClientProfileIdsResponse> {
            return localVarFp.getClientProfileIds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information necessary to configure the SPA.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig(options?: any): AxiosPromise<SPAConfig> {
            return localVarFp.getConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Feature flag settings for the SPA.
         * @param {string} [xOmnidianClientProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlags(xOmnidianClientProfileId?: string, options?: any): AxiosPromise<FeatureFlags> {
            return localVarFp.getFlags(xOmnidianClientProfileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpaControllerApi - object-oriented interface
 * @export
 * @class SpaControllerApi
 * @extends {BaseAPI}
 */
export class SpaControllerApi extends BaseAPI {
    /**
     * 
     * @summary Record one or more log records from the SPA.
     * @param {SPAAppendLogRequest} sPAAppendLogRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaControllerApi
     */
    public appendLog(sPAAppendLogRequest: SPAAppendLogRequest, options?: RawAxiosRequestConfig) {
        return SpaControllerApiFp(this.configuration).appendLog(sPAAppendLogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the list of profiles available for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaControllerApi
     */
    public getClientProfileIds(options?: RawAxiosRequestConfig) {
        return SpaControllerApiFp(this.configuration).getClientProfileIds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information necessary to configure the SPA.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaControllerApi
     */
    public getConfig(options?: RawAxiosRequestConfig) {
        return SpaControllerApiFp(this.configuration).getConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Feature flag settings for the SPA.
     * @param {string} [xOmnidianClientProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaControllerApi
     */
    public getFlags(xOmnidianClientProfileId?: string, options?: RawAxiosRequestConfig) {
        return SpaControllerApiFp(this.configuration).getFlags(xOmnidianClientProfileId, options).then((request) => request(this.axios, this.basePath));
    }
}



